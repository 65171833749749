// utils/mixpanel.js
import mixpanel from 'mixpanel-browser'

// Initialize Mixpanel
mixpanel.init('04766eabb4bbfeb5ec69701c317bfeb4')

// Conditionally require and initialize CleverTap on the client side only
let clevertap
if (typeof window !== 'undefined') {
  clevertap = require('clevertap-web-sdk')
  clevertap.init('R97-9RK-Z47Z', 'eu1')
  clevertap.enablePersonalization = true
}
const countries = {
  3: 'USA',
  2: 'UK',
  4: 'Canada',
  9: 'Australia/New Zealand',
  6: 'Singapore',
  11: 'Hong Kong',
  73: 'Other',
}

const premiumStatus = {
  null: 'Basic',
  1: 'Basic',
  2: 'Plus',
  3: 'Premium',
  4: 'Premium Plus',
}

const gradesKeyValue = {
  1: 'Grade 8',
  2: 'Grade 9',
  3: 'Grade 10',
  4: 'Grade 11',
  5: 'Grade 12',
  8: 'In College',
  9: 'Graduated',
  7: 'Others',
}

const curriculumKeyValue = {
  1: 'IGCSE',
  2: 'IB',
  3: 'CBSE',
  4: 'ICSE',
  5: 'STATE BOARD',
  6: 'CAIE',
  7: 'UNDERGRADUATE/ DEGREE',
}

// Utility function to track events
export const trackEvent = (eventName, properties = {}, userDetails = null) => {
  const eventProps = {
    source: 'Website',
    ...properties,
    ...(userDetails
      ? {
          ID: userDetails.ID,
          FULL_NAME: `${userDetails.FIRSTNAME} ${userDetails.LASTNAME}`,
          PHONENUMBER: userDetails.PHONENUMBER,
          EMAIL: userDetails.EMAIL,
          ISPREMIUM: userDetails.ISPREMIUM ? premiumStatus[userDetails.ISPREMIUM] : 1,
          GRADE: gradesKeyValue[userDetails.GRADE],
          CURRICULUM: curriculumKeyValue[userDetails.CURRICULUM],
          IS_PARENT: Boolean(userDetails.IS_PARENT),
        }
      : userDetails),
  }

  // Track event in Mixpanel
  mixpanel.track(eventName, eventProps)
  // Function to push event to CleverTap with retries if not initialized
  const pushCleverTapEvent = (attempt = 0) => {
    if (clevertap && clevertap.event && typeof clevertap.event.push === 'function') {
      try {
        console.log('Pushed the event', clevertap)

        clevertap.event.push(eventName, eventProps)
      } catch (err) {
        console.error('Error pushing event to CleverTap:', err)
      }
    } else if (attempt < 5) {
      // Wait 500ms and try again (retry up to 5 times)
      setTimeout(() => pushCleverTapEvent(attempt + 1), 500)
    } else {
      console.warn('CleverTap is not initialized yet.')
    }
  }

  pushCleverTapEvent()
}

export const handleLogin = (email, name, phone) => {
  try {
    if (typeof window !== 'undefined' && window.clevertap) {
      // Identify the user
      clevertap.onUserLogin({
        Site: {
          Email: email, // Primary identifier
          Name: name, // Optional
          Phone: phone, // Optional
        },
      })
    }
  } catch (Err) {
    console.log('Error while login', Err)
  }
}

export const updateProfileData = (userDetails) => {
  try {
    if (typeof window !== 'undefined' && window.clevertap) {
      // Identify the user
      clevertap.profile.push({
        Site: {
          ...(userDetails.GRADE ? { Grade: gradesKeyValue[userDetails.GRADE] } : {}),
          ...(userDetails.CURRICULUM ? { Curriculum: curriculumKeyValue[userDetails.CURRICULUM] } : {}),
        },
      })

      userDetails.countries?.length &&
        clevertap.push('personal_preference_screen_country', {
          'Country Choice': userDetails.countries?.map((item) => item.TAG_NAME).join(','),
        })

      userDetails.GRADE &&
        clevertap.push('personal_preference_screen_grade', {
          Grade: userDetails.GRADE,
        })
      userDetails.CURRICULUM &&
        clevertap.push('personal_preference_screen_curriculum', {
          Curriculum: curriculumKeyValue[userDetails.CURRICULUM],
        })
    }
  } catch (Err) {
    console.log('Error while login', Err)
  }
}

// utils/authHelpers.js
export const trackEventWithAuthStatus = (auth, eventName, properties = {}) => {
  if (
    auth.isAuthenticated === true &&
    auth.loading === false &&
    auth.expiry_date !== undefined &&
    auth.userDetails.EMAIL
  ) {
    console.log("I'm authenticated")
    trackEvent(eventName, properties, auth.userDetails)
  } else if (auth.isAuthenticated === false && auth.loading === false) {
    console.log("I'm not authenticated")
    trackEvent(eventName, properties)
  }
}
